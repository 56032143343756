import React, { useEffect, useState } from "react"
import { Page } from "../../../../components/Page"
import { Column } from "../../../../components/UI/Grid/Column"
import { Row } from "../../../../components/UI/Grid/Row"
import { VerticalOffset } from "../../../../components/UI/Grid/VerticalOffset"
import { Button } from "../../../../components/UI/Button"
import { BodyRegularM } from "../../../../components/UI/Text"
import { TableData, TableDataItem, TablePaymentStatus } from "../../../../components/UI/TableData"
import { Divider } from "../../../../components/UI/Divider"
import { Badge } from "../../../../components/UI/Badge"
import { QrCode } from "../../../../components/UI/QrCode"
import { IconText } from "../../../../components/UI/IconText"
import { Mark } from "../../../../components/UI"
import { CopyText } from "../../../../components/UI/CopyText"
import { IPayDataRequest } from "../../../../models/IPayCurrencyDataRequest"
import { useAppSelector } from "../../../../app/hooks"
import { selectStatus } from "../../invoiceSlice"
import { ICurrency } from "../../../../models/ICurrency"
import { Img } from "../../../../components/UI/Img"
import TimeUpdate from "../../../../utils/time"
import { PaymentStatus } from "../../../../components/PaymentStatus"
import { useNavigate } from "react-router-dom"
import { languageConstants } from "../../../language/languageSlice"
import { CopyItem } from "../../../../components/UI/CopyText/CopyText"
import Services from "../../../../utils/services"

type StepCryptoDetailsProps = {
  paymentCurrency: ICurrency
  data: IPayDataRequest
  payCancel: () => void
}

export const StepCryptoPay: React.FC<StepCryptoDetailsProps> = ({ data, paymentCurrency, payCancel }) => {
  const langConstants = useAppSelector(languageConstants)
  const status = useAppSelector(selectStatus)
  const [seconds, setSeconds] = useState(status?.time || 0)
  const [time, setTime] = useState(TimeUpdate.secondsToHms(status?.time))
  const navigate = useNavigate();

  useEffect(() => {
    const updateStatusInterval = setInterval(() => {
      setSeconds(preSecond => preSecond - 1)
    }, 1000);
    return () => {
      clearInterval(updateStatusInterval)
    }
  }, [])
  useEffect(() => {
    if (status) setSeconds(status.time)
  }, [status])

  useEffect(() => {
    setTime(TimeUpdate.secondsToHms(seconds))
  }, [seconds])


  return (
    <Page title={langConstants["L_PAYMENTS_BY_BITCOIN"]} width="full">
      <VerticalOffset offset="level5">
        <Row rowGap="level1">
          {status?.result &&
            <Column col={12}>
              <PaymentStatus status={status.result.type} message={status.result.message} />
            </Column>
          }
          <Column col={12}>
            <TableData>
              <TableDataItem
                name={`${langConstants["L_YOU_ARE_PAYING"]}`}
                value={{
                  text: `${langConstants["L_INVOICE_ID"]}: ${data.id}`,
                }}
              />

              <TableDataItem
                name={`${langConstants["L_INVOICE_AMOUNT"]}`}
                value={{
                  text: `${data.amount} ${status?.invoiceCurrency.displayName}`,
                  color: "secondary",
                }}
              />
              {data.itemName &&
                <TableDataItem
                  name={`${langConstants["L_PRODUCT_NAME"]}`}
                  value={{
                    text: `${data.itemName}`,
                    color: "secondary",
                  }}
                />
              }
              {(status && status.commissionOrBonus) ?
                <TableDataItem
                  name={status.commissionOrBonus > 0 ? langConstants["L_COMMISSION_PROCESSING_FEE"] : langConstants["L_BONUS_PROCESSING_FEE"]}
                  value={{
                    text: `${status.commissionOrBonus > 0 ? status.commissionOrBonus * 100 : status.commissionOrBonus * (-100)} %`,
                    color: "secondary",
                  }}
                /> : null}
                
              {status ?
                <TablePaymentStatus status={status.result.type} message={status.result.message} />
                : null}
            </TableData>
          </Column>
            <Column col={12}>
              <Row justify="space-between">
                <Column col={9}>
                  <Badge text={langConstants["L_FOUNDS_NOT_RECEIVED"]} />
                  {/* <Badge text={`L_SIMPLE_PROCESSING_TRANSFER_STATUS_${data.status}`} /> */}
                </Column>
                <Column col={"auto"}>
                  <Badge text={`${time}`} />
                </Column>
              </Row>
            </Column>
        </Row>
    
        {(status && status.coinAddress && status.amountToPay) ?
          <Row rowGap="level1" justify="center">
            <Column col={12}>
              <QrCode value={status.btcLink} />
            </Column>
            <Column col={6} alignSelf="center">
              <CopyItem value={`${Services.numberWithoutExp(status.amountToPay)}`}>
                <div className="text--color-danger">You have to transfer this amount:</div>
                <IconText justify="center" align="center">
                  <Mark mark="primary">{`${Services.numberWithoutExp(status.amountToPay)} ${paymentCurrency.name}`}</Mark>
                  {paymentCurrency.linkToIcon ?
                    <Img src={paymentCurrency.linkToIcon} size="icon" alt={paymentCurrency.name} />
                    :
                    null
                  }
                </IconText>
              </CopyItem>
            </Column>
            <Column col={8}>
              <CopyText>{status.coinAddress}</CopyText>
            </Column>
            <Column col={8}>
              <Divider type="solid" />
            </Column>
            <Column col={8}>
              <Row rowGap="level3" align="center" justify="space-between">
                <Column col={12} sm={{ col: "auto" }}>
                  <a href={status.btcLink}>
                    <Button width="full-mobile" size="medium" >
                      <BodyRegularM>{langConstants["L_PAY_FROM_CRYPTO_WALLET"]}</BodyRegularM>
                    </Button>
                  </a>
                </Column>

                <Column col={12} sm={{ col: "auto" }}>
                  <Button width="full-mobile" variant="secondary" size="medium" onClick={payCancel}>
                    <BodyRegularM>{langConstants["L_CANCEL"]}</BodyRegularM>
                  </Button>
                </Column>
              </Row>
            </Column>
          </Row>
          : null}
        {/* </>} */}
      </VerticalOffset>
    </Page>
  )
}
